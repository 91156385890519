#main {
    height: 100%;
    min-height: 1200px;
    /*    margin: 0;
        padding: 0;
        box-sizing: border-box;*/
    /*    background: radial-gradient(125.5% 125.5% at 48.6% 100%, rgb(162, 0, 255) 0%, rgb(2, 4, 49) 100%);*/
    background: radial-gradient(125.5% 125.5% at 48.6% 100%, rgb(0, 255, 166) 0%, rgb(2, 4, 49) 100%);
}

.spacer-up {
    margin-top: 14rem;
}
/* CSS */
.float-image {
    animation: float 3s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -20px); /* Adjust the values to your liking */
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes slide-up {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -20px); /* Adjust the values to your liking */
    }
    100% {
        transform: translate(0, 0);
    }

}
.iphone-container {
    position: relative;
    width: 50px; /* Adjust to the size of your iPhone frame */
    height: 100px; /* Adjust to the size of your iPhone frame */
    margin: 0 auto;
    z-index: 1;
}


.jump-in {
    animation: fadeIn 1s, slideIn .8s linear;
}
@keyframes slideIn {
    0% {
        transform: translateY(400px);
        animation-timing-function: ease-out;
    }
    60% {
        transform: translateY(-30px);
        animation-timing-function: ease-in;
    }
    80% {
        transform: translateY(10px);
        animation-timing-function: ease-out;
    }
    100% {
        transform: translateY(0px);
        animation-timing-function: ease-in;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.scroll-down-arrow {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
    background-size: contain;
    background-repeat: no-repeat;
}

.scroll-down-link {
    position: absolute;
    z-index: 100;
    cursor: pointer;
    height: 60px;
    width: 80px;
    margin: 0px 0 0 -40px;
    line-height: 60px;
    left: 50%;
    bottom: 10px;
    color: #FFF;
    text-align: center;
    font-size: 70px;
    text-decoration: none;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    -webkit-animation: fade_move_down 2s ease-in-out infinite;
    -moz-animation: fade_move_down 2s ease-in-out infinite;
    animation: fade_move_down 2s ease-in-out infinite;
}
/*animated scroll arrow animation*/
@keyframes fade_move_down {
    0%   { transform:translate(0,-20px); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { transform:translate(0,20px); opacity: 0; }
}
