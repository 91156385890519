body {
    color: black;
    background: #f5f5f5;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
}

.finished {
    background: #7df09b;
}


.table-wrapper {
    background: #fff;
    padding: 20px 25px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
}

td{
    vertical-align: middle;
}


.blink_me {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

.status {
    font-size: 45px;
    margin: 4px 3px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
}



