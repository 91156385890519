.ready{
    --clr:#faeb46;
    --bg: #181717;
}
.finished{
    --clr: #41be25;
    --bg: #181717;
}


.cool-button {

    position: relative;
    background: #faf084;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
}

.cool-button:hover {
    background: var(--clr);
    color: var(--clr);
    box-shadow: 0 0 35px var(--clr);
    animation: box 3s infinite;
}

.cool-button::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: var(--bg);
}

.cool-button span {
    position: relative;
    z-index: 1;
}
.cool-button i {
    position: absolute;
    inset: 0;
    display: block;
}

.cool-button i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 4px;
    left: 80%;
    top: 0;
    border: 2px solid var(--clr);
    background: var(--bg);
    transition: 0.2s;
}

.cool-button:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
}

.cool-button i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 4px;
    left: 20%;
    bottom: 0;
    border: 2px solid var(--clr);
    background: var(--bg);
    transition: 0.2s;
}

.cool-button:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
}

@keyframes move {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes box {
    0% {
        box-shadow: var(--bg);
    }
    50% {
        box-shadow: 0 0 25px var(--clr);
    }
    100% {
        box-shadow: var(--bg);
    }
}
