

.bg-ready{
    background-color: #faf084 !important;
}


/*@keyframes slideOut {
    0% {
        transform: translateX(0px);
        animation-timing-function: ease-out;
    }
    60% {
        transform: translateX(600px);
        animation-timing-function: ease-in;
    }
    80% {
        transform: translateX(1000px);
        animation-timing-function: ease-out;
    }
    100% {
        transform: translateX(3000px);
        animation-timing-function: ease-in;
    }
}*/

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
